
* {
    box-sizing: border-box;
}

body {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    background: #111;
    color: #eee;
    user-select: none;
    font-family: 'JetBrains Mono Bold', source-code-pro, Menlo, Monaco, Consolas,
                 'Courier New';
    text-size-adjust: none;
}


.outline-text {
    text-shadow: -1px -1px #111, 1px -1px #111, 1px 1px #111, -1px 1px #111;
}
.active-outline {
}
.active-outline:before {
    content: '';
    position: absolute;
    left: 1px;
    top:1px;
    right: 1px;
    bottom: 1px;
    outline: solid 4px white;
}
.active-outline:after {
    content: '';
    position: absolute;
    left: 0;
    top:0;
    right: 0;
    bottom: 0;
    outline: solid 2px #111;
}
.active-outline.resize-canvas:after {
    outline: solid 2px limegreen;
}

.button {
    background: #444;
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    margin-bottom: 8px;
    margin-right: 8px;
    cursor: pointer;
    user-select: none;
    min-width: 32px;
    text-align: center;
}
.button:hover {
    background: #555;
}
.toggle {
    position: relative;
    padding-left: 24px;
}
.toggle:after {
    content: '';
    position: absolute;
    left: 8px;
    top: calc(50% - 5px);
    width: 8px;
    height: 8px;
    background: #eee;
    border-radius: 3px;
}
.toggle.active,
.button.active {
    background: #ddd;
    color: #222;
}
.toggle.active:after {
    background: #222;
}

.keyname {
    background: #ccc;
    color: #222;
    padding-left: 4px;
    padding-right: 4px;
}
.keytip {
    color: #aaa;
}
.heading {
    border-top: solid 1px #666;
    padding-top: 8px;
}
.divider {
    height: 1px;
    background: #666;
}

.toggle.active .keyname {
    background: #aaa; 
}

.toggle.active .keyname {
    background: #aaa; 
}
.toggle.disabled, .toggle.active.disabled {
    background: #555;
    color: #888;
}
.toggle.active.disabled:after, .toggle.disabled:after {
    background: #888;
}
.toggle.active.disabled .keyname, .toggle.disabled .keyname {
    background: #888; 
    color: #555
}

a {
    color: inherit;
}



